import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class AlertDialog extends React.Component {

  state = {
    open: false,
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{zIndex: 1301 /*Maior que um popover, para 'excluir anexo' aparecer sobre a janela de 'anexos'*/ }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {this.props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { if (this.props.disagree) this.props.disagree();} } color="primary">
            CANCELAR
          </Button>
          <Button onClick={() => { if (this.props.agree) this.props.agree();} } color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;