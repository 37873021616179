import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import LinksTopo from "./LinksTopo.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

class Topo extends React.Component {
  state = {
    auth: null,
    settings: [],
  };

  componentWillReceiveProps(props) {
    if (
      this.state.auth != props.auth ||
      this.state.settings.length != props.routes.settings.length
    ) {
      this.setState({
        auth: props.auth,
        settings: props.routes.settings,
      });
    }
  }

  render() {
    let brandName = "";
    const props = this.props;

    const setBrandName = (prop, key) => {
      if (prop.redirect) {
        return "";
      }
      if (prop.items) {
        prop.items.forEach(setBrandName);
        return;
      }
      //Evitando nome de caminho coringa
      if (!prop.path || prop.path == "/") {
        return;
      }

      let reg = new RegExp(prop.path + "$");
      if (reg.test(window.location.pathname)) {
        brandName =
          typeof prop.navbarName == "function"
            ? prop.navbarName(props.auth)
            : prop.navbarName;
      }
    };
    props.routes.menu.forEach(setBrandName);
    this.state.settings.forEach(setBrandName);

    const { classes, color } = props;
    const appBarClasses = classNames({
      [" " + classes[color]]: color,
    });

    return (
      <AppBar className={classes.appBar + appBarClasses + " TopoAppBar"}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button
              color="transparent"
              onClick={() => document.location.reload()}
              className={classes.title}
            >
              {brandName}
            </Button>
          </div>
          <div className="hidePrint">
            <Hidden smDown implementation="css">
              <LinksTopo routes={this.state.settings} auth={props.auth} />
            </Hidden>
            <Hidden mdUp implementation="css">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Topo.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default withStyles(headerStyle)(Topo);
