import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import Button from "components/CustomButtons/Button.jsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { ListItemText } from "@material-ui/core";

const API_URL = document.procEnv.API_URL;
const basePath = document.procEnv.BASE_PATH + "/";

function activeRoute(routeName) {
  let reg = new RegExp(basePath + routeName + "$");
  return reg.test(window.location.pathname);
}

class LinksTopo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      openMenu: false,
      routes: [],
      auth: props.auth,
    };

    this.prepareRoutes();
  }

  prepareRoutes = () => {
    let routes = [];
    this.props.routes
      .sort((a, b) => {
        let aName =
          typeof a.sidebarName == "function"
            ? a.sidebarName(this.state.auth)
            : a.sidebarName ?? "";
        let bName =
          typeof b.sidebarName == "function"
            ? b.sidebarName(this.state.auth)
            : b.sidebarName ?? "";
        return aName.localeCompare(bName);
      })
      .forEach((route) => {
        if (route.devel && API_URL.indexOf("localhost") == -1) {
          return null;
        }

        if (this.state.auth && this.state.auth.permission && !route.devel) {
          let formularios = Object.keys(this.state.auth.permission);
          if (formularios.indexOf(route.path.replace(basePath, "")) < 0) {
            return null;
          }
        }
        if (route.menu === false || (route.auth && !this.state.auth)) {
          return null;
        }
        routes.push(route);
      });
    this.setState({ routes });
  };

  componentWillReceiveProps = (props) => {
    if (
      props.auth != this.state.auth ||
      this.state.routes.length != props.routes.length
    ) {
      this.state.auth = props.auth;
      this.prepareRoutes();
    }
  };

  handleToggle = (name) => () => {
    this.setState((state) => ({
      open: false,
      openMenu: false,
      [name]: !state[name],
    }));
  };

  handleClose = (name) => (event) => {
    if (
      (this.anchorEl && this.anchorEl.contains(event.target)) ||
      (this.anchorElMenu && this.anchorElMenu.contains(event.target))
    ) {
      return;
    }

    this.setState({ [name]: false });
  };

  render() {
    const { classes } = this.props;
    const { openMenu, routes } = this.state;

    return (
      <div>
        {!routes.length ? null : (
          <div className={classes.manager}>
            <Button
              buttonRef={(node) => {
                this.anchorElMenu = node;
              }}
              color={"transparent"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-label="Quadro"
              className={classes.buttonLink}
              onClick={this.handleToggle("openMenu")}
              style={{ fontSize: "1em" }}
            >
              <Settings className={classes.icons} />
              <Hidden mdUp>
                <p className={classes.linkText}>Configurações</p>
              </Hidden>
            </Button>
            <Poppers
              open={openMenu}
              anchorEl={this.anchorElMenu}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openMenu }) +
                " " +
                classes.pooperNav
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={this.handleClose("openMenu")}
                    >
                      <MenuList role="menu">
                        {routes.map((menuItem, key) => {
                          const listItemClasses = classNames({
                            [" " + classes["purple"]]: activeRoute(
                              menuItem.path
                            ),
                          });
                          const whiteFontClasses = classNames({
                            [" " + classes.whiteFont]: activeRoute(
                              menuItem.path
                            ),
                          });
                          return (
                            <NavLink
                              to={menuItem.path}
                              className={classes.item}
                              activeClassName="active"
                              key={key}
                            >
                              <MenuItem
                                className={classes.itemLink + listItemClasses}
                                onClick={this.handleClose("openMenu")}
                              >
                                <ListItemIcon
                                  className={
                                    classes.itemIcon + whiteFontClasses
                                  }
                                >
                                  {typeof menuItem.icon === "string" ? (
                                    <Icon>{menuItem.icon}</Icon>
                                  ) : (
                                    <menuItem.icon />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={menuItem.sidebarName}
                                  className={
                                    classes.itemText + whiteFontClasses
                                  }
                                  disableTypography={true}
                                />
                              </MenuItem>
                            </NavLink>
                          );
                        })}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(LinksTopo);
