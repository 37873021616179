import axios from 'axios';

axios.defaults.baseURL = document.procEnv.API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';

const AUTH_TOKEN = localStorage.getItem('authorization');
if (AUTH_TOKEN) {
    axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
}

axios.setRequestFns = (fnSuc, fnErr) => {
    axios.interceptors.request.use((r) => {
        if (fnSuc && typeof fnSuc === 'function') {
            return fnSuc(r);
        }
        return r;
    }, (e) => {
        if (fnErr && typeof fnErr === 'function') {
            fnErr(e);
        }
        return Promise.reject(e);
    })
};
 
axios.setResponseFns = (fnSuc, fnErr) => {
    axios.interceptors.response.use((r) => {
        if (fnSuc && typeof fnSuc === 'function') {
            return fnSuc(r);
        }
        return r;
    }, (e) => {
        if (fnErr && typeof fnErr === 'function') {
            fnErr(e);
        }
        return Promise.reject(e);
    })
};

export default axios;